.visible-desktop {}
.visible-mobile {
    display: none;
}

@media (max-width: 600px) {
    .visible-desktop {
        display: none;
    }
    .visible-mobile {
        display: block;
    }
}


.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}